.iframe {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	padding:0;
	height: 100%;
	border: none;
	width: 100%;
	max-width: none !important;
	max-height: none !important;
	box-sizing: border-box;

	overflow-y: auto !important;
	-webkit-overflow-scrolling:touch !important;
}

.wrapper {
	position: fixed !important;
	display: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	opacity: 0;
	width: 100%;
	transform: scale(0);

	font:inherit;
	font-size:100%;
	padding:0;
	vertical-align:baseline;
	border:0;
	box-sizing: border-box;
	background-color: #fafafa;

	overflow-y: auto !important;
	-webkit-overflow-scrolling:touch !important;
}

.default {
	@extend .iframe;
	@extend .wrapper;
}

.fullPage {
	display: block;
	transform: scale(1);
	opacity: 1;
}

.active {
	display: block;
	z-index: 2147483647;
	transition: transform .4s ease, 
	opacity .4s ease, top .3s ease, height .3s ease;
}

.visible {
	opacity: 1;
	transform: scale(1);
	transition: transform .4s ease, 
	opacity .4s ease;
}

.custom {
  height: 100vh;
	padding:0;
	border: none;
	width: 100%;
	max-width: none !important;
	box-sizing: border-box;
  display: block;
}